import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import { BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import RouteChangeTracker from './RouteChangeTracker';

function App() {

   const TRACKING_ID = "G-80W0RQ0M95"; // YOUR_OWN_TRACKING_ID
   ReactGA.initialize(TRACKING_ID);

   return (
      <div>

         <BrowserRouter>
            <RouteChangeTracker />
            <BaseLayout />
         </BrowserRouter>
      </div>
   );
}


export default App;
