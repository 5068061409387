import React, { useState } from 'react';
import Style from './Navbar.module.scss';
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { info } from "../info/Info";
// import logo from "../img/logo.png"

const links = [
    {
        name: info.initials,
        type: 'initials',
        to: '/',
        active: 'home'
    },
]

export default function Navbar({ darkMode, handleClick }) {
    const location = useLocation()
    const [active, setActive] = useState(location.pathname === '/' ? 'home' : location.pathname.slice(1, location.pathname.length));

    return (
        <Box component={'nav'} width={'100%'}>
            <Box component={'ul'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                gap={{ xs: '2rem', md: '8rem' }}
                textTransform={'lowercase'} fontSize={'1rem'}>
                {links.map((link, index) => (
                    <Box key={index} component={'li'} className={(link.active === active && !link.type) && Style.active}
                        sx={{ borderImageSource: info.gradient }}>
                        {/* <Link to={'https://adnananwarali.com'} onClick={() => setActive(link.active)} className={Style.link}> */}
                        <div style={{marginTop: "20px", marginBottom: "20px"}}>
                            {/* {<img style={{ paddingTop: '0.5rem 0' }} height={130} src={logo} />} */}

                        </div>
                        {/* </Link> */}
                    </Box>
                ))}
            </Box>
        </Box>
    )
}