import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
// import SocialIcon from "./SocialIcon";
import {Box} from "@mui/material";
import {info} from "../../info/Info";
import { SocialIcon } from 'react-social-icons'

export default function Home() {

   return (
      <Box component={'main'} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} alignItems={'center'}
           justifyContent={'center'} minHeight={'calc(100vh - 175px)'}>
         <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{objectFit: "cover"}} component={'img'} src={me} width={{xs: '35vh', md: '50vh'}}
              height={{xs: '35vh', md: '50vh'}}
              borderRadius={'50%'} 
            //   p={'0.75rem'} 
              mb={{xs: '1rem', sm: 0}} mr={{xs: 0, md: '2rem'}}/>
         <Box maxWidth={"800px"}>
            <h2 style={{marginTop: "20px", marginBottom: "20px"}}>Hi, I'm</h2>
            <h1 style={{marginTop: "20px", marginBottom: "20px", color: "#fb6d43"}}>
               {info.firstName}
            </h1>
            {/* <h2 style={{marginTop: "20px", marginBottom: "20px"}}>{info.position}</h2> */}
            <Box component={'ul'} px={'0.8rem'}>
               {info.miniBio.map((bio, index) => (
                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text}/>
               ))}
            </Box>
            <Box display={'flex'} gap={'1.5rem'} justifyContent={{xs: 'center', md: 'left'}} fontSize={{xs: '2rem', md: '2.5rem'}} style={{marginTop: "20px", marginBottom: "20px"}}>
               <SocialIcon url="https://www.facebook.com/AdnanAnwar97" />
               <SocialIcon url="https://www.linkedin.com/in/adnananwarali/" />
               <SocialIcon url="mailto:hello@adnananwarali.com" />
            </Box>
         </Box>
      </Box>
   )
}